<template>
  <section id="header" class="clearfix cd-secondary-nav">
    <nav class="navbar navbar-default">
      <div class="container clearfix">
        <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header clearfix page-scroll">
          <button
            type="button"
            class="navbar-toggle"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a href="/" class="navbar-brand"
            ><i class="fa fa-pencil"></i> Museum <span>Art</span></a
          >
        </div>

        <!-- Collect the nav links, forms, and other content for toggling -->
        <div
          class="collapse navbar-collapse clearfix"
          id="bs-example-navbar-collapse-1"
        >
          <ul class="nav navbar-nav navbar-right">
            <li>
              <a class="tag_menu_1 active_tab" href="/">HOME</a>
            </li>

            <li class="dropdown">
              <a
                class="tag_menu border_none"
                href="#"
                data-toggle="dropdown"
                role="button"
                aria-expanded="false"
                >EVENTS<span class="caret"></span
              ></a>
              <ul class="dropdown-menu drop_2" role="menu">
                <li><a href="/exhibitions">EVENTS</a></li>
                <!-- <li><a href="detail.html">EVENTS DETAIL</a></li> -->
              </ul>
            </li>

            <li class="dropdown">
              <a
                class="tag_menu border_none"
                href="#"
                data-toggle="dropdown"
                role="button"
                aria-expanded="false"
                >BLOG<span class="caret"></span
              ></a>
              <ul class="dropdown-menu drop_2" role="menu">
                <li><a href="/blog">BLOG</a></li>
                <!-- <li><a href="blog_detail.html">BLOG DETAIL</a></li> -->
              </ul>
            </li>

            <li>
              <a class="tag_menu" href="/gallery">GALLERY</a>
            </li>

            <li>
              <a class="tag_menu" href="/contact">CONTACT US</a>
            </li>

            <li class="dropdown">
              <a class="tag_menu" href="#" data-toggle="dropdown"
                ><span class="fa fa-search"></span
              ></a>
              <ul class="dropdown-menu drop_1" style="min-width: 300px">
                <li>
                  <div class="row_1">
                    <div class="col-sm-12">
                      <form class="navbar-form navbar-left" role="search">
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Search"
                          />
                          <span class="input-group-btn">
                            <button class="btn btn-primary" type="button">
                              Search
                            </button>
                          </span>
                        </div>
                      </form>
                    </div>
                  </div>
                </li>
              </ul>
            </li>

            <li class="dropdown">
              <a
                class="tag_menu border_none"
                href="#"
                data-toggle="dropdown"
                role="button"
                aria-expanded="false"
                >MORE<span class="caret"></span
              ></a>
              <ul class="dropdown-menu drop_2" role="menu">
                <li><a href="/exhibitions">EVENTS</a></li>
                <li><a href="/gallery">GALLERY</a></li>
              </ul>
            </li>
          </ul>
        </div>
        <!-- /.navbar-collapse -->
      </div>
      <!-- /.container-fluid -->
    </nav>
  </section>
</template>
