<template>
  <HeaderItem />

  <section id="center">
    <div class="clearfix">
      <div
        id="carousel-example-generic"
        class="carousel slide"
        data-ride="carousel"
      >
        <ol class="carousel-indicators">
          <li
            data-target="#carousel-example-generic"
            data-slide-to="0"
            class="active"
          ></li>
          <li
            data-target="#carousel-example-generic"
            data-slide-to="1"
            class=""
          ></li>
          <li
            data-target="#carousel-example-generic"
            data-slide-to="2"
            class=""
          ></li>
        </ol>
        <div class="carousel-inner">
          <div class="item active">
            <img src="img/home1.jpeg" alt="First slide" />
            <div class="carousel-caption"></div>
          </div>
          <div class="item">
            <img src="img/home2.jpeg" alt="Second slide" />
            <div class="carousel-caption"></div>
          </div>
          <div class="item">
            <img src="img/home3.jpeg" alt="Third slide" />
            <div class="carousel-caption"></div>
          </div>
        </div>
      </div>
      <div class="main-text hidden-xs">
        <div class="col-md-12">
          <div class="col-sm-4">
            <div class="center_slide">
              <h1>MUSEUM ART <br />EXHIBITION CENTER</h1>
              <p>Send us your idea we make it an art for you.</p>
              <h4><a href="#">GET REGISTER</a></h4>
              <h6>
                <a href="#"
                  >SHOW ELEMENTUM WORLD <i class="fa fa-play-circle"></i
                ></a>
              </h6>
            </div>
          </div>
          <div class="col-sm-3"></div>
          <div class="col-sm-5"></div>
        </div>
      </div>
    </div>
  </section>

  <section id="deals_page">
    <div class="container">
      <div class="row">
        <div class="deals_page clearfix">
          <div class="col-sm-6">
            <div class="deals_page_left bg_1 clearfix">
              <div class="col-sm-5">
                <div class="deals_page_left_1 card-image">
                  <a href="#"
                    ><img src="img/7.jpg" width="100%" height="185px"
                  /></a>
                </div>
              </div>
              <div class="col-sm-7 space_left">
                <div class="deals_page_left_2">
                  <h6>Running</h6>
                  <h3>
                    <a href="#"
                      >Litora torquent per nostra, per inceptos himenaeos</a
                    >
                  </h3>
                  <p>Fusce nec augue semper porta</p>
                  <div class="clearfix deals_page_left_2_inner">
                    <a class="tag_1" href="#">Get Info</a>
                    <a class="tag_2" href="#"
                      >Get this Offer <i class="fa fa-arrow-right"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="deals_page_left clearfix">
              <div class="col-sm-5">
                <div class="deals_page_left_1 card-image">
                  <a href="#"
                    ><img src="img/8.jpg" width="100%" height="185px"
                  /></a>
                </div>
              </div>
              <div class="col-sm-7 space_left">
                <div class="deals_page_left_2">
                  <h6>Expire in 22 days</h6>
                  <h3>
                    <a href="#"
                      >Litora torquent per nostra, per inceptos himenaeos</a
                    >
                  </h3>
                  <p>Fusce nec augue semper porta</p>
                  <div class="clearfix deals_page_left_2_inner">
                    <a class="tag_1" href="#">Get Info</a>
                    <a class="tag_2" href="#"
                      >Get this Offer <i class="fa fa-arrow-right"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="deals_page clearfix">
          <div class="col-sm-6">
            <div class="deals_page_left clearfix">
              <div class="col-sm-5">
                <div class="deals_page_left_1 card-image">
                  <a href="#"
                    ><img src="img/9.jpg" width="100%" height="185px"
                  /></a>
                </div>
              </div>
              <div class="col-sm-7 space_left">
                <div class="deals_page_left_2">
                  <h6>Expire in 12 days</h6>
                  <h3>
                    <a href="#"
                      >Litora torquent per nostra, per inceptos himenaeos</a
                    >
                  </h3>
                  <p>Fusce nec augue semper porta</p>
                  <div class="clearfix deals_page_left_2_inner">
                    <a class="tag_1" href="#">Get Info</a>
                    <a class="tag_2" href="#"
                      >Get this Offer <i class="fa fa-arrow-right"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="deals_page_left bg_1 clearfix">
              <div class="col-sm-5">
                <div class="deals_page_left_1 card-image">
                  <a href="#"
                    ><img src="img/10.jpg" width="100%" height="185px"
                  /></a>
                </div>
              </div>
              <div class="col-sm-7 space_left">
                <div class="deals_page_left_2">
                  <h6>Expire in 600 days</h6>
                  <h3>
                    <a href="#"
                      >Litora torquent per nostra, per inceptos himenaeos</a
                    >
                  </h3>
                  <p>Fusce nec augue semper porta</p>
                  <div class="clearfix deals_page_left_2_inner">
                    <a class="tag_1" href="#">Get Info</a>
                    <a class="tag_2" href="#"
                      >Get this Offer <i class="fa fa-arrow-right"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="deals_page clearfix">
          <div class="col-sm-6">
            <div class="deals_page_left bg_1 clearfix">
              <div class="col-sm-5">
                <div class="deals_page_left_1 card-image">
                  <a href="#"
                    ><img src="img/11.jpg" width="100%" height="185px"
                  /></a>
                </div>
              </div>
              <div class="col-sm-7 space_left">
                <div class="deals_page_left_2">
                  <h6>Expire in 666 days</h6>
                  <h3>
                    <a href="#"
                      >Litora torquent per nostra, per inceptos himenaeos</a
                    >
                  </h3>
                  <p>Fusce nec augue semper porta</p>
                  <div class="clearfix deals_page_left_2_inner">
                    <a class="tag_1" href="#">Get Info</a>
                    <a class="tag_2" href="#"
                      >Get this Offer <i class="fa fa-arrow-right"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="deals_page_left clearfix">
              <div class="col-sm-5">
                <div class="deals_page_left_1 card-image">
                  <a href="#"
                    ><img src="img/12.jpg" width="100%" height="185px"
                  /></a>
                </div>
              </div>
              <div class="col-sm-7 space_left">
                <div class="deals_page_left_2">
                  <h6>Expire in 14 days</h6>
                  <h3>
                    <a href="#"
                      >Litora torquent per nostra, per inceptos himenaeos</a
                    >
                  </h3>
                  <p>Fusce nec augue semper porta</p>
                  <div class="clearfix deals_page_left_2_inner">
                    <a class="tag_1" href="#">Get Info</a>
                    <a class="tag_2" href="#"
                      >Get this Offer <i class="fa fa-arrow-right"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="blog_post">
    <div class="blog_post_main clearfix">
      <div class="container">
        <div class="row">
          <div class="clinic_home text-center clearfix">
            <h1>LATEST BLOGS</h1>
            <p>
              Sed augue semper porta. Vestibulum lacinia arcu eget nulla.Class
              aptent taciti sociosqu ad litora torquent per<br />
              conubia nostra, per inceptos himenaeos Sed dignissim lacinia nunc.
            </p>
          </div>
          <div class="blog_post clearfix">
            <div class="col-sm-4">
              <div class="blog_post_inner">
                <div class="card-image">
                  <img src="img/47.jpg" width="100%" height="300px" />
                </div>
                <h5>Nov, 08th, 2017</h5>
                <h4>
                  <a href="#">Nulla quis sem at nibh elementum imperdiet?</a>
                </h4>
                <p>
                  Fusce nec tellus sed augue semper porta Mauris massa
                  Vestibulum lacinia arcu eget nulla class aptent taciti
                </p>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="blog_post_inner">
                <div class="card-image">
                  <img src="img/48.jpg" width="100%" height="300px" />
                </div>
                <h5>Nov, 08th, 2017</h5>
                <h4>
                  <a href="#"
                    >Duis sagittis ipsum Praesent mauris nec tellus?</a
                  >
                </h4>
                <p>
                  Fusce nec tellus sed augue semper porta Mauris massa
                  Vestibulum lacinia arcu eget nulla class aptent taciti
                </p>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="blog_post_inner">
                <div class="card-image">
                  <img src="img/49.jpg" width="100%" height="300px" />
                </div>
                <h5>Nov, 08th, 2017</h5>
                <h4>
                  <a href="#"
                    >Vestibulum lacinia arcu eget nulla class aptent taciti
                    ?&#8203;&#8203;&#8203;&#8203;&#8203;&#8203;&#8203;</a
                  >
                </h4>
                <p>
                  Fusce nec tellus sed augue semper porta Mauris massa
                  Vestibulum lacinia arcu eget nulla class aptent taciti
                </p>
              </div>
            </div>
          </div>
          <div class="services_home_2 text-center clearfix">
            <h5><a href="#">View Our Blogs</a></h5>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="team_home">
    <div class="container">
      <div class="row">
        <div class="about_home text-center clearfix">
          <h1 class="heading_tag">Our Staff</h1>
          <p class="para_2">
            Nulla quis sem at nibh elementum imperdiet Duis sagittis ipsum
            Praesent mauris Fusce<br />
            nec tellus sed augue semper porta.
          </p>
        </div>
        <div class="team_home clearfix">
          <article class="col-sm-3">
            <div class="panel panel-default">
              <div class="panel-body">
                <a
                  href="img/800/1.jpg"
                  title="Museum Art"
                  class="zoom"
                  data-title="Museum Art"
                  data-footer="Repairing Center"
                  data-type="image"
                  data-toggle="lightbox"
                >
                  <img src="img/300/1.jpg" alt="Nature Portfolio" />
                  <span class="overlay"
                    ><i class="glyphicon glyphicon-fullscreen"></i
                  ></span>
                </a>
              </div>
            </div>
            <div class="team_home_inner text-center clearfix">
              <h4><a href="#">Praesent Libero</a></h4>
              <p>
                Elementum Imperdiet Duis Sagittis<br />
                Ipsum Praesent Mauris Fusce
              </p>
            </div>
          </article>

          <article class="col-sm-3">
            <div class="panel panel-default">
              <div class="panel-body">
                <a
                  href="img/800/2.jpg"
                  title="Museum Art"
                  class="zoom"
                  data-title="Museum Art"
                  data-footer="Repairing Center"
                  data-type="image"
                  data-toggle="lightbox"
                >
                  <img src="img/300/2.jpg" alt="Nature Portfolio" />
                  <span class="overlay"
                    ><i class="glyphicon glyphicon-fullscreen"></i
                  ></span>
                </a>
              </div>
            </div>
            <div class="team_home_inner text-center clearfix">
              <h4><a href="#">Dapibus Diam</a></h4>
              <p>
                Elementum Imperdiet Duis Sagittis<br />
                Ipsum Praesent Mauris Fusce
              </p>
            </div>
          </article>

          <article class="col-sm-3">
            <div class="panel panel-default">
              <div class="panel-body">
                <a
                  href="img/800/3.jpg"
                  title="Museum Art"
                  class="zoom"
                  data-title="Museum Art"
                  data-footer="Repairing Center"
                  data-type="image"
                  data-toggle="lightbox"
                >
                  <img src="img/300/3.jpg" alt="Food Portfolio" />
                  <span class="overlay"
                    ><i class="glyphicon glyphicon-fullscreen"></i
                  ></span>
                </a>
              </div>
            </div>
            <div class="team_home_inner text-center clearfix">
              <h4><a href="#">Nulla Quis</a></h4>
              <p>
                Elementum Imperdiet Duis Sagittis<br />
                Ipsum Praesent Mauris Fusce
              </p>
            </div>
          </article>

          <article class="col-sm-3">
            <div class="panel panel-default">
              <div class="panel-body">
                <a
                  href="img/800/4.jpg"
                  title="Museum Art"
                  class="zoom"
                  data-title="Museum Art"
                  data-footer="Repairing Center"
                  data-type="image"
                  data-toggle="lightbox"
                >
                  <img src="img/300/4.jpg" alt="Sports Portfolio" />
                  <span class="overlay"
                    ><i class="glyphicon glyphicon-fullscreen"></i
                  ></span>
                </a>
              </div>
            </div>
            <div class="team_home_inner text-center clearfix">
              <h4><a href="#">Sed Augue</a></h4>
              <p>
                Elementum Imperdiet Duis Sagittis<br />
                Ipsum Praesent Mauris Fusce
              </p>
            </div>
          </article>
        </div>
      </div>
    </div>
  </section>

  <section id="collection_detail">
    <div class="container">
      <div class="row">
        <div class="collection_detail clearfix">
          <h3>KNOW THE CATEGORIES</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec
            odio.Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla
            quis sem at nibh elementum imperdiet. Duis sagittis ipsum.Praesent
            mauris. Fusce nec tellus sed augue semper porta. Mauris massa.
            Vestibulum lacinia arcu eget nulla.Class aptent taciti sociosqu ad
            litora torquent per conubia nostra, per inceptos himenaeos.
            Curabitursodales ligula in libero.Sed dignissim lacinia nunc.
            Integer nec odio.Praesent libero. Sed cursus ante dapibus diam. Sed
            nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis
            ipsum.Praesent mauris.
          </p>
        </div>
        <div class="collection_detail_1 collection_detail_home clearfix">
          <div class="col-sm-4 space_left">
            <div class="collection_detail_1_left clearfix">
              <div class="grid clearfix">
                <figure class="effect-marley">
                  <img src="img/31.jpg" alt="img12" />
                  <figcaption>
                    <h2>Museum <span>Art</span></h2>
                    <p>Nulla quis sem at nibh elementum imperdiet.</p>
                    <a href="#">View more</a>
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
          <div class="col-sm-8">
            <div class="collection_detail_1_right">
              <h2>Take:Ahead</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                nec odio.Praesent libero. Sed cursus ante dapibus diam. Sed
                nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis
                ipsum.Praesent mauris. Fusce nec tellus sed augue semper porta.
                Mauris massa. Vestibulum lacinia arcu eget nulla.Class aptent
                taciti sociosqu ad litora torquent per conubia nostra.
              </p>
              <h4><a href="#">READ MORE</a></h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="connect" class="gallery_page">
    <div class="container">
      <div class="row">
        <div class="connect clearfix">
          <h2>GALLERY</h2>
        </div>
        <div class="connect_1 clearfix">
          <div class="gallery">
            <a href="img/18.jpg" class="big"
              ><img src="img/thumbs/18.jpg" alt="" title="Beautiful Image"
            /></a>
            <a href="img/19.jpg"
              ><img src="img/thumbs/19.jpg" alt="" title=""
            /></a>
            <a href="img/20.jpg"
              ><img src="img/thumbs/20.jpg" alt="" title="Beautiful Image"
            /></a>
            <a href="img/21.jpg"
              ><img src="img/thumbs/21.jpg" alt="" title=""
            /></a>
            <div class="clear"></div>

            <a href="img/22.jpg"
              ><img src="img/thumbs/22.jpg" alt="" title=""
            /></a>
            <a href="img/23.jpg"
              ><img src="img/thumbs/23.jpg" alt="" title=""
            /></a>
            <a href="img/24.jpg" class="big"
              ><img src="img/thumbs/24.jpg" alt="" title=""
            /></a>
            <a href="img/25.jpg"
              ><img src="img/thumbs/25.jpg" alt="" title=""
            /></a>
            <div class="clear"></div>

            <a href="img/26.jpg" class="big"
              ><img src="img/thumbs/26.jpg" alt="" title=""
            /></a>
            <a href="img/27.jpg"
              ><img src="img/thumbs/27.jpg" alt="" title=""
            /></a>
            <a href="img/28.jpg"
              ><img src="img/thumbs/28.jpg" alt="" title=""
            /></a>
            <a href="img/29.jpg"
              ><img src="img/thumbs/29.jpg" alt="" title=""
            /></a>
            <div class="clear"></div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="sign_up" class="sign_up_collection">
    <div class="container">
      <div class="row">
        <div class="sign_up_box text-center clearfix">
          <h2>
            Vestibulum lacinia arcu eget nulla aptent taciti sociosqu ad litora
          </h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec
            odio.Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla
            quis sem at nibh <br />elementum imperdiet Duis sagittis ipsum.
          </p>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="your email address"
            />
            <span class="input-group-btn">
              <button class="btn btn-primary" type="button">SUBMIT</button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="about_home" class="clearfix">
    <div class="about_home_main clearfix">
      <div class="container">
        <div class="row">
          <div class="about_home clearfix">
            <div class="col-sm-6">
              <div class="about_home_1 clearfix">
                <div class="col-sm-3 space_all">
                  <div class="about_home_1_left">
                    <h2><i class="fa fa-skyatlas"></i></h2>
                  </div>
                </div>
                <div class="col-sm-9">
                  <div class="about_home_1_right">
                    <h4>OUR TOURS</h4>
                    <p>
                      Fusce nec tellus sed augue semper porta. Mauris massa.
                      Vestibulum lacinia arcu eget nulla.Class aptent taciti
                      sociosqu ad litora torquent per conubia nostra.
                    </p>
                  </div>
                </div>
              </div>
              <div class="about_home_1 clearfix">
                <div class="col-sm-3 space_all">
                  <div class="about_home_1_left">
                    <h2><i class="fa fa-television"></i></h2>
                  </div>
                </div>
                <div class="col-sm-9">
                  <div class="about_home_1_right">
                    <h4>FOREIGN TOURS</h4>
                    <p>
                      Fusce nec tellus sed augue semper porta. Mauris massa.
                      Vestibulum lacinia arcu eget nulla.Class aptent taciti
                      sociosqu ad litora torquent per conubia nostra.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="about_home_1 clearfix">
                <div class="col-sm-3 space_all">
                  <div class="about_home_1_left">
                    <h2><i class="fa fa-user-plus"></i></h2>
                  </div>
                </div>
                <div class="col-sm-9">
                  <div class="about_home_1_right">
                    <h4>COMMUNICATION</h4>
                    <p>
                      Fusce nec tellus sed augue semper porta. Mauris massa.
                      Vestibulum lacinia arcu eget nulla.Class aptent taciti
                      sociosqu ad litora torquent per conubia nostra.
                    </p>
                  </div>
                </div>
              </div>
              <div class="about_home_1 clearfix">
                <div class="col-sm-3 space_all">
                  <div class="about_home_1_left">
                    <h2><i class="fa fa-suitcase"></i></h2>
                  </div>
                </div>
                <div class="col-sm-9">
                  <div class="about_home_1_right">
                    <h4>COMPANY PROGRESS</h4>
                    <p>
                      Fusce nec tellus sed augue semper porta. Mauris massa.
                      Vestibulum lacinia arcu eget nulla.Class aptent taciti
                      sociosqu ad litora torquent per conubia nostra.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="footer">
    <div class="container">
      <div class="row">
        <div class="footer clearfix">
          <div class="footer_1 clearfix">
            <div class="col-sm-3">
              <div class="footer_1_left">
                <h3>Museum Art of Lacinia</h3>
                <p>228 Dapibus Road East<br />Porta, Lacinia<br />NTU 2H5</p>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="footer_3 clearfix">
                <p>Registration Open * 11234 0512 SS1110</p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Integer nec odio.Praesent libero. Sed cursus ante dapibus
                  diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet.
                  Duis sagittis ipsum.Praesent mauris. Fusce nec tellus sed
                  augue semper porta.© 2013 Your Website Name. All Rights
                  Reserved | Design by
                  <a href="http://www.templateonweb.com">TemplateOnWeb</a>
                </p>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="footer_1_right pull-right">
                <h1>AMZ <span>Museum Art of Lacinia</span></h1>
              </div>
            </div>
          </div>
          <div class="footer_2 clearfix">
            <div class="col-sm-7">
              <div class="footer_2_left">
                <a href="#">Sign Up For Headlines</a>
                <a href="#">Customer Detail</a>
                <a href="#">Connect With Us</a>
                <a href="#">Media</a>
                <a href="#">Work</a>
                <a class="border_none_1" href="#">Policy</a>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="footer_2_right pull-right">
                <ul class="social-network social-circle">
                  <li>
                    <a href="#" class="icoRss" title="Rss"
                      ><i class="fa fa-rss"></i
                    ></a>
                  </li>
                  <li>
                    <a href="#" class="icoFacebook" title="Facebook"
                      ><i class="fa fa-facebook"></i
                    ></a>
                  </li>
                  <li>
                    <a href="#" class="icoTwitter" title="Twitter"
                      ><i class="fa fa-twitter"></i
                    ></a>
                  </li>
                  <li>
                    <a href="#" class="icoGoogle" title="Google +"
                      ><i class="fa fa-google-plus"></i
                    ></a>
                  </li>
                  <li>
                    <a href="#" class="icoLinkedin" title="Linkedin"
                      ><i class="fa fa-linkedin"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div
    id="toTop"
    class="btn btn-info"
    style="display: block; background: #e02626; border-color: #e02626"
  >
    <span class="fa fa-chevron-up"></span>
  </div>
</template>
<script>
import HeaderItem from '../components/HeaderItem.vue'
export default {
  components: {
    HeaderItem
  }
}
</script>
